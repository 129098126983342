import { Component } from '@angular/core';
import { MenuController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { ConstantVariable } from 'constant-variable';
import { AuthenticationService } from 'services/authentication/authentication.service';
import { ConfigService } from 'services/config/config.service';
import { ProgressService } from 'services/progress/progress.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  constructor(
    private platform: Platform,
    private authService: AuthenticationService,
    private configService: ConfigService,
    private progressService: ProgressService,
    private menuCtrl: MenuController,
    private translate: TranslateService,
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.configService.loadConfig();
    });
    this.authService.initializeApp();

    this.translate.setDefaultLang(ConstantVariable.defaultLang);
    this.translate.use(ConstantVariable.defaultLang);
  }

  ngOnInit() {
    this.progressService.initializeApp();
  }

  ionViewWillEnter() {
    this.menuCtrl.enable(true);
  }
}
