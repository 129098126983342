import { AfterViewChecked, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { KpiService } from 'services/hr-cockpit/kpi.service';

@Component({
  selector: 'app-kpi',
  templateUrl: './kpi.component.html',
  styleUrls: ['./kpi.component.scss'],
})
export class KpiComponent implements OnChanges, AfterViewChecked {
  @ViewChild('grid') grid;
  @Input() settings;
  @Input() widgetWidth;
  @Input() widgetHeight = 1;
  @Input() sizeChange;
  @Input() id;

  kpiCircles: Kpi = [{value: '', label: ''},{value: '', label: ''}];
  isDesigned = false;
  isOpen = false;
  localSettings;

  constructor(
    private kpiService: KpiService,
  ) { }

  applyDesign() {
    this.isDesigned = true;
    // second word in value smaller is design specification
    this.grid.el.querySelectorAll('DIV.kpi__circle').forEach((element, index) => {
      let kpiValue = '';
      const value = this.kpiCircles[index].value;
      if(typeof value === 'string') {
        kpiValue = value.trim();
      }
      if(kpiValue !== '') {
        element.replaceChildren(
          ...kpiValue
          .split(' ')
          .map((ele, i, a) => {
            const span = document.createElement('DIV');
            if(i === a.length - 1 && i > 0) {
              // applying only to last element and only if more than 1 element
              span.setAttribute('style', 'font-size: 1.0rem');
            }
            span.append(ele);
            return span as Node;
          })
        );
      } else {
        this.isDesigned = false;
      }
    });
  }

  getKpi() {
    this.kpiService.getKpi(this.settings.kpi, this.settings.primarysource, this.settings.secondarysource).subscribe((data) => {
      this.kpiCircles = data;
      this.isDesigned = false;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.settings !== undefined) {
      this.getKpi();
    }
  }

  ngAfterViewChecked(): void {
    if(!this.isDesigned) {
      this.applyDesign();
    }
  }

}
