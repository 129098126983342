import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-job-list',
  templateUrl: './job-list.component.html',
  styleUrls: ['./job-list.component.scss'],
})
export class JobListComponent  implements OnInit {
  @Input() settings;
  @Input() sizeChange;
  @Input() id;
  @Input() widgetWidth;
  @Input() widgetHeight;

  constructor() { }

  ngOnInit() {}

}
