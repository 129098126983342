// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  width: var(--he-sidebar-width);
  display: inline-block;
  height: 100%;
  vertical-align: top;
}

.sidebar {
  background-color: var(--he-sidebar-background);
  min-height: 100%;
}

/* keep in sync width content.component.ts and https://ionicframework.com/docs/layout/grid#default-breakpoints */
@media (max-width: 576px) {
  :host {
    width: 100%;
    border-top: 1px solid var(--ion-color-step-150, #d7d8da);
  }
}
@media (min-width: 576px) {
  :host {
    border-left: 1px solid var(--ion-color-step-150, #d7d8da);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/sidebar/sidebar.component.scss"],"names":[],"mappings":"AAAA;EACI,8BAAA;EACA,qBAAA;EACA,YAAA;EACA,mBAAA;AACJ;;AAEA;EACI,8CAAA;EACA,gBAAA;AACJ;;AAEA,gHAAA;AACA;EACI;IACI,WAAA;IAEA,wDAAA;EAAN;AACF;AAEA;EACI;IAEI,yDAAA;EADN;AACF","sourcesContent":[":host {\n    width: var(--he-sidebar-width);\n    display: inline-block;\n    height: 100%;\n    vertical-align: top;\n}\n\n.sidebar {\n    background-color: var(--he-sidebar-background);\n    min-height: 100%;\n}\n\n/* keep in sync width content.component.ts and https://ionicframework.com/docs/layout/grid#default-breakpoints */\n@media (max-width: 576px) {\n    :host {\n        width: 100%;\n        // color copied from ionic divider\n        border-top: 1px solid var(--ion-color-step-150, #d7d8da)\n    }\n}\n@media (min-width: 576px) {\n    :host {\n        // color copied from ionic divider\n        border-left: 1px solid var(--ion-color-step-150, #d7d8da)\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
