// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-image {
  background-image: url("/assets/images/widget_Stellenliste_Platzhalter.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/hr-cockpit/widgets/job-list/job-list.component.scss"],"names":[],"mappings":"AAAA;EACE,2EAAA;EACA,wBAAA;EACA,2BAAA;EACA,4BAAA;EACA,YAAA;AACF","sourcesContent":[".background-image {\n  background-image: url('/assets/images/widget_Stellenliste_Platzhalter.svg');\n  background-size: contain;\n  background-position: center;\n  background-repeat: no-repeat;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
