import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class JobApplicationService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getJobApplication(
    settings: {
      source: string;
      presentation: string;
      period: string;
      more?: string[];
    },
    lazyLoad?: {
      limit: number;
      offset: number;
    }
  ): Observable<JobApplication[]> {
    const params = new HttpParams({
      fromObject: { ...settings, ...lazyLoad},
    });

    // (mock mode) somehow does not accept .get(url, {params}), so params is attached as string (params.toString())
    return this.http.get<JobApplication[]>(
      `/api/hr-cockpit/jobApplications?${params.toString()}`
    );
  }

}
