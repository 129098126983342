import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgProgress, NgProgressModule, NgProgressRef } from 'ngx-progressbar';

export const PROGRESS_CONFIG = NgProgressModule.withConfig({
  trickleSpeed: 100,
  spinner: false,
  min: 0,
  meteor: true,
  debounceTime: 1000, // debounce wird über delay selbst gemacht
});

/**
 * Progess Service, als Kapselung / Helpter zu SlimLoadingBarService
 * 
 * usage:
  <ng-progress
    id="app-progressbar"
    [color]="progressService.loadingBarColor"
  ></ng-progress>

  ngOnInit() {
      this.progressService.initializeApp();
  }
 */
@Injectable({
  providedIn: 'root'
})
export class ProgressService {
  progressRef: NgProgressRef;

  public loadingBarColor;

  /**
   * @param ngProgressService 
   * @param sanitizer 
   */
  constructor(
    private ngProgressService: NgProgress,
    private sanitizer: DomSanitizer
  ) {
    this.init();
  }

  initializeApp() {
    this.init();
    this.progressRef = this.ngProgressService.ref('app-progressbar');
  }

  init() {
    this.colorDefault();
  }

  /**
   * PRIVATE
   */
  private colorDefault() {
    this.loadingBarColor = this.sanitizer.bypassSecurityTrustStyle('var(--ion-color-primary)');
  }

  private colorError() {
    this.loadingBarColor = this.sanitizer.bypassSecurityTrustStyle('var(--ion-color-danger)');
  }

  /**
   *  PUBLIC state switches
   */
  error(url: string, apiReplaced: boolean) {
    if (!apiReplaced) {
      return;
    }
    this.colorError();
    this.progressRef.complete();
  }

  complete(url: string, apiReplaced: boolean) {
    if (!apiReplaced) {
      return;
    }
    this.progressRef.complete();
  }

  start(url: string, apiReplaced: boolean) {
    if (!apiReplaced) {
      return;
    }
    this.colorDefault();
    this.progressRef.start();
  }
}
