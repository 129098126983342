import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-invoice-overview',
  templateUrl: './invoice-overview.component.html',
  styleUrls: ['./invoice-overview.component.scss'],
})
export class InvoiceOverviewComponent  implements OnInit {
  @Input() settings;
  @Input() sizeChange;
  @Input() id;
  @Input() widgetWidth;
  @Input() widgetHeight;

  constructor() { }

  ngOnInit() {}

}
