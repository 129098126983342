// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  text-align: center;
  font-weight: 600;
  letter-spacing: 1.2px;
}

img {
  margin: 0.5rem 1rem 0.5rem 0;
}

ion-item:hover {
  --color: #fff;
}

ion-label {
  border-radius: 100px;
  border: 1px solid var(--color-two);
  padding: 0.25rem;
  text-align: center;
  color: var(--color-two) !important;
  font-weight: 500;
}

ion-label:hover,
ion-label.selected {
  background-color: var(--color-two);
  color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/hr-cockpit/widgets/links/links.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;EACA,qBAAA;AACF;;AAEA;EACE,4BAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,oBAAA;EACA,kCAAA;EACA,gBAAA;EACA,kBAAA;EACA,kCAAA;EACA,gBAAA;AACF;;AAEA;;EAEE,kCAAA;EACA,uBAAA;AACF","sourcesContent":["h2 {\n  text-align: center;\n  font-weight: 600;\n  letter-spacing: 1.2px;\n}\n\nimg {\n  margin: 0.5rem 1rem 0.5rem 0;\n}\n\nion-item:hover {\n  --color: #fff;\n}\n\nion-label {\n  border-radius: 100px;\n  border: 1px solid var(--color-two);\n  padding: 0.25rem;\n  text-align: center;\n  color: var(--color-two) !important;\n  font-weight: 500;\n}\n\nion-label:hover,\nion-label.selected {\n  background-color: var(--color-two);\n  color: white !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
