import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { AlertOptions } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(
    private toastController: ToastController,
    private alertController: AlertController,
    private translate: TranslateService,
  ) { }

  /**
   * Schließt die aktuelle Meldung.
   */
  public dismiss() {
    // ignore success/error of dismiss
    this.toastController.dismiss().then(d => { }, e => { });
  }

  /**
   * Erzeugt eine Fehler-Meldung mit gegebenem Text.
   * @param msg Text
   */
  async error(msg: string) {
    this.dismiss();
    const toast = await this.toastController.create({
      message: msg,
      color: 'danger',
      position: 'top',
      buttons: [{
        icon: 'close',
        role: 'cancel',
      }]
    });
    await toast.present();
  }

  /**
   * @param [msgKey] Text-Key für Warnuings-Meldung
   */
  async warning(msgKey?: string, translateMessage: boolean = true) {
    this.dismiss();
    const msg = translateMessage && msgKey ? await firstValueFrom(this.translate.get(msgKey)) : msgKey;
    const toast = await this.toastController.create({
      message: msg,
      color: 'warning',
      position: 'top',
      duration: 3000
    });
    await toast.present();
  }

  /**
   * Erzeugt eine Erfolgs-Meldung mit gegebenem Text-Key, der übersetzt wird.
   * Wird kein Text-Key übergeben, wird die Standard-Meldung angezeigt.
   * Die Meldung wird 2 Sekunden lang angezeigt, bevor sie verschwindet.
   * @param [msgKey] Text-Key für Erfolgs-Meldung
   */
  async success(msgKey?: string, translateMessage: boolean = true) {
    this.dismiss();
    const msg = translateMessage ? await firstValueFrom(this.translate.get(msgKey || 'global.messages.success')) : msgKey;
    const toast = await this.toastController.create({
      message: msg,
      color: 'success',
      position: 'top',
      duration: 3000
    });
    await toast.present();
  }

  /**
   * Erzeugt ein Bestätigen-Popup für Löschen. Es muss der Name des zu löschenden Eintrags übergeben werden,
   * da dieser in die anzuzeigende Nachricht geschrieben wird.
   * Die OK-Aktion muss und die Abbrechen-Aktion kann übergeben werden.
   * @param name der Name des Eintrags
   * @param handleOk OK-Aktion
   * @param [handleCancel] Abbrechen-Aktion
   */
  async confirmDelete(name: string, handleOk: any, handleCancel?: any) {
    const delHeader = await firstValueFrom(this.translate.get('global.popup.deleteHeader', { name: name }));
    const delMsg = await firstValueFrom(this.translate.get('global.popup.deleteMsg', { name: name }));

    this.confirmInternal({
      header: delHeader,
      message: delMsg,
    }, handleOk, handleCancel);
  }

  /**
   * Erzeugt ein Bestätigungs-Popup (OK und Abbrechen) anhand der gegebenen Argumente.
   * Die OK-Aktion muss und die Abbrechen-Aktion kann übergeben werden.
   * @param name der Name des Eintrags
   * @param handleOk OK-Aktion
   * @param [handleCancel] Abbrechen-Aktion
   */
  async confirm(header: string, message: string, handleOk: any, handleCancel?: any) {
    this.confirmInternal({
      header: header,
      message: message,
    }, handleOk, handleCancel);
  }

  /**
   * Erzeugt ein Bestätigungs-Popup (OK und Abbrechen) anhand der gegebenen Optionen.
   * Die OK-Aktion muss und die Abbrechen-Aktion kann übergeben werden.
   * @param opts sind die Optionen
   * @param handleOk OK-Aktion
   * @param [handleCancel] Abbrechen-Aktion
   */
  private async confirmInternal(opts: AlertOptions, handleOk: any, handleCancel?: any) {
    const txtOk = await firstValueFrom(this.translate.get('global.action.confirm'));
    const txtCancel = await firstValueFrom(this.translate.get('global.action.cancel'));

    const baseOpts: AlertOptions = {
      header: 'Confirm!',
      message: '',
      buttons: [
        {
          text: txtCancel,
          role: 'cancel',
          handler: handleCancel
        }, {
          text: txtOk,
          handler: handleOk
        }
      ]
    };
    const toast = await this.alertController.create({ ...baseOpts, ...opts });
    await toast.present();
  }
}
