import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EnvironmentService } from 'services/environment/environment.service';
import { ProgressService } from '../services/progress/progress.service';

/**
 * replaces /api from url with
 */
@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor(
        private progressService: ProgressService,
    ) { }


    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let mocked = false;
        let requestUrl = request.url || '';
        let requestMethod = request.method;
        let requestHeaders = request.headers;

        /**
         * MOCK daten
         * */
        if (EnvironmentService.isMock()) {
            requestUrl = EnvironmentService.doMock(requestUrl);
            mocked = request.url !== requestUrl;
            if (mocked) {
                console.debug('mocking', requestMethod, request.url, requestUrl, request.body);

                requestHeaders = requestHeaders.delete('Authorization');
                if (requestMethod !== 'GET') {
                    requestMethod = 'GET';
                }
            }
        }

        /**
         * prepend /api => api-url.domain.eu/api...
         */
        const apiReplace = EnvironmentService.doApiReplace(requestUrl);
        if (apiReplace.replaced) {
            requestUrl = apiReplace.url;
        }

        if (request.url !== requestUrl) {
            request = request.clone({
                url: requestUrl,
                method: requestMethod,
                headers: requestHeaders
            });
        }

        /**
         * RESULT
         */
        this.progressService.start(request.url, apiReplace.replaced);

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    this.progressService.complete(request.url, apiReplace.replaced);
                }
                return event;
            }),
            catchError(err => {
                this.progressService.error(request.url, apiReplace.replaced);
                return throwError(() => err);
            })
        );
    }
}
