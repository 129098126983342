import { Component, OnInit } from '@angular/core';
import { Config } from 'entities/config';
import { ConfigService } from 'services/config/config.service';
import { ConsentService } from 'services/consent/consent.service';

@Component({
  selector: 'app-anonymous-privacy',
  templateUrl: './anonymous-privacy.component.html',
  styleUrls: ['./anonymous-privacy.component.scss'],
})
export class AnonymousPrivacyComponent implements OnInit {

  config: Config;

  constructor(
    public consentService: ConsentService,
    private configService: ConfigService,
  ) {}

  ngOnInit() {
    this.configService.loadConfig().subscribe(respone => this.config = respone);
  }

}
