// konfiguration für mock daten im serve modus
import { ConstantVariable, TestUrlMapping } from "constant-variable";

const PUBLIC_URL = "";

export const environment = {
    name: 'dev',
    production: false,
    mock: true,
    version: 'DEV_MOCK',
    apiUrl: "",
};

const testPrefix = 'assets/test/';
TestUrlMapping
    .set(ConstantVariable.apiPrefix + '/auth/login', testPrefix + 'empty.json')
    .set(ConstantVariable.apiPrefix + '/logout', testPrefix + 'empty.json')
    .set(ConstantVariable.apiPrefix + '/config', testPrefix + 'config.json')
    .set(ConstantVariable.apiPrefix + '/secure/oauth/token', testPrefix + 'token.json')
    .set(ConstantVariable.apiPrefix + '/i18n/texts/', `assets/i18n/${ConstantVariable.defaultLang}.json`)
    .set(ConstantVariable.apiPrefix + '/hr-cockpit/widgetCreation', testPrefix + 'widgetCreations.json')
    .set(ConstantVariable.apiPrefix + '/hr-cockpit/widgetShopItem', testPrefix + 'widgetShopItems.json')
    .set(ConstantVariable.apiPrefix + '/hr-cockpit/dashboard', testPrefix + 'dashboards.json')
    .set(ConstantVariable.apiPrefix + '/hr-cockpit/kpi?kpi=timeToHire', testPrefix + 'kpi-tth.json')
    .set(ConstantVariable.apiPrefix + '/hr-cockpit/kpi?kpi=timeToInterview', testPrefix + 'kpi-tti.json')
    .set(ConstantVariable.apiPrefix + '/hr-cockpit/kpi?kpi=costPerHire', testPrefix + 'kpi-cph.json')
    .set(ConstantVariable.apiPrefix + '/hr-cockpit/kpi?kpi=vacancies', testPrefix + 'kpi-v.json')
    .set(
      ConstantVariable.apiPrefix + '/hr-cockpit/jobApplications?source=newest&presentation=tiles',
      testPrefix + 'jobApplications-tiles-newest.json'
    )
    .set(
      ConstantVariable.apiPrefix + '/hr-cockpit/jobApplications?source=lastEdited&presentation=tiles',
      testPrefix + 'jobApplications-tiles.json'
    ).set(
      ConstantVariable.apiPrefix + '/hr-cockpit/jobApplications?source=newest&presentation=list',
      testPrefix + 'jobApplications-list-newest.json'
    )
    .set(
      ConstantVariable.apiPrefix + '/hr-cockpit/jobApplications?source=lastEdited&presentation=list',
      testPrefix + 'jobApplications-list.json'
    );
