import { TranslateService } from '@ngx-translate/core';

export const getSaveDashboardAlert = (translationService: TranslateService) => ({
    header: translationService.instant('alert.saveDashboard.header'),
    message: translationService.instant('alert.saveDashboard.message'),
    buttons: [
      {text: translationService.instant('alert.saveDashboard.button.cancel')},
      {text: translationService.instant('alert.saveDashboard.button.save'), role: 'confirm', cssClass: 'alert-button-confirm'}]
  });

export const getDeleteDashboardAlert = (translationService: TranslateService) => ({
  header: translationService.instant('alert.deleteDashboard.header'),
  message: translationService.instant('alert.deleteDashboard.message'),
  buttons: [
    {text: translationService.instant('alert.deleteDashboard.button.cancel')},
    {text: translationService.instant('alert.deleteDashboard.button.delete'), role: 'confirm', cssClass: 'alert-button-confirm'}]
});

export const getResetDashboardAlert = (translationService: TranslateService) => ({
    header: translationService.instant('alert.resetDashboard.header'),
    message: translationService.instant('alert.resetDashboard.message'),
    buttons: [
      {text: translationService.instant('alert.resetDashboard.button.cancel')},
      {text: translationService.instant('alert.resetDashboard.button.reset'), role: 'confirm', cssClass: 'alert-button-confirm'}]
  });

export const getDeleteGridstackSectionAlert = (translationService: TranslateService) => ({
    header: translationService.instant('alert.deleteGridstackSection.header'),
    message: translationService.instant('alert.deleteGridstackSection.message'),
    buttons: [
      {text: translationService.instant('alert.deleteGridstackSection.button.cancel')},
      {text: translationService.instant('alert.deleteGridstackSection.button.delete'), role: 'confirm', cssClass: 'alert-button-confirm'}]
  });
