import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

// TODO issue check if required, otherwise remove => also can be interface for  storageService
@Injectable({
  providedIn: 'root'
})
export class CachingService {

  /** Key-Value Speicher */
  private cache: Map<string, Observable<any>>;

  constructor() {
    this.cache = new Map();
  }

  /**
   * Speichert ein Key-Value Paar im Cache.
   * @param key der Key, anhand dessen der Wert später geladen werden kann
   * @param value der zu speichernde Wert
   */
  public store(key: string, value: any): void {
    this.cache.set(key, value);
  }

  /**
   * Lädt ein Observable-Objekt anhand eines Keys aus dem Cache.
   * @param key der Key zur Identifikation
   */
  public load(key: string): Observable<any> {
    return from(this.cache.get(key));
  }

  /**
   * Prüft, ob der gegebene Key im Cache verwendet wird.
   * @param key der Key zur Identifikation
   */
  public contains(key: string): boolean {
    return this.cache.has(key);
  }

  /**
   * Löscht ein Key-Value Paar anhand eines Keys aus dem Cache.
   * @param key der Key zur Identifikation
   */
  public remove(key: string): boolean {
    return this.cache.delete(key);
  }

  /**
   * Löscht alle Daten aus dem Cache.
   */
  public clear(): void {
    this.cache.clear();
  }
}
