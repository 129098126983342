import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from 'services/api/api.service';
import { AuthenticationService } from 'services/authentication/authentication.service';
import { CachingService } from 'services/caching/caching.service';
import { Config } from 'entities/config';
import { catchError, Observable, onErrorResumeNext } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class ConfigService extends ApiService {
  constructor(
    protected cachingService: CachingService,
    protected authService: AuthenticationService,
    protected http: HttpClient
  ) {
    super(cachingService, http, 'config');
  }

  /**
   * Lädt die App-Konfiguration.
   * @param force gibt an, ob ein Request geschickt werden soll mit User-Login Daten
   */
  public loadConfig(force: boolean = false): Observable<Config> {
    if (force || this.authService.isAuthenticated()) {
      return this.httpGet('/user', !force).pipe(
        catchError(err => {
          console.debug('error loading config fallback to public.', err);
          return this.loadPublicConfig();
        })
      );
    } else {
      return this.loadPublicConfig();
    }
  }

  loadPublicConfig(): Observable<Config> {
    return this.httpGet('/public/get', true);
  }
}