import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthenticationService } from 'services/authentication/authentication.service';
import { ConfigService } from 'services/config/config.service';
import { ENTRIES } from 'components/menu/menu.entries';
import { from, map, Observable, of } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(
    public auth: AuthenticationService,
    public configService: ConfigService,
    public router: Router,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.auth.isAuthenticated()) {
      // authorised so return true, if not disabled
      return this.isNotDisabledMenu(route);
    }
    // not logged in so redirect to login page with the return url
    console.debug('authentiation guard returns false, forward to login');
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
    return of(false);
  }

  /**
  * Prüft ob aktuelle Navigationspunkt auch aktiv ist.
  * Überprüft wird start (erster teil bis /) Pfad auf ENTRIES.key
  * 
  * @param route 
  */
  private isNotDisabledMenu(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.configService.loadConfig().pipe(
      map(config => {
        let result = false
        const currentPathFull = route.routeConfig.path;
        let currentPathStart = route.routeConfig.path.split('/')[0];


        ////
        // disabledMenus not available yet
        result = true;
        ////

        // if (!config.disabledMenus || !currentPathStart) {
        //   result = true;
        // } else {
        //   let currentPathStartIsMenu = ENTRIES.some(e => currentPathStart === e.key);
        //   let filteredMenuEntries = ENTRIES.filter(e => config.disabledMenus.indexOf(e.key) < 0);

        //   // full path is in disabled
        //   if (config.disabledMenus.some(a => a === currentPathFull)) {
        //     result = false;
        //   } else {
        //     // prüfe ob menu aktuellen pfad erlaubt hat
        //     result = filteredMenuEntries.some(e => currentPathStart === e.key);
        //   }

        //   // wenn nicht erlaubt, aber menu punkt ist erlaubt
        //   if (!result && currentPathStartIsMenu && filteredMenuEntries.length > 0) {
        //     this.router.navigate([filteredMenuEntries[0].url]);
        //   }
        //   result = false;
        // }
        return result;
      })
    );
  }
}