import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

/**
 * Beispiel: <iframe [src]="'https:/url/der/ressource' | trustResourceUrl"/>
 */
@Pipe({
  name: 'TrustResourceUrl'
})
export class TrustResourceUrlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) { }

  transform(url: string): SafeUrl {
      if (!url) {
          return null;
      }

      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}