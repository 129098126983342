
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable, Pipe, PipeTransform, Optional } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ObservableInput, Subject } from 'rxjs';
import { EnvironmentService } from 'app-services';
import { ENTRIES } from 'assets/images/mockUpImage.entries';
import { MockUpImage } from 'entities/mockUpImage';

// import { MessageProvider } from 'services/message/message.provider';

/**
 * Purpose: enable AuthInterceptor to intercept image requests that should get the X-Auth-Token ...
 *
 * usage e.g.:
 * <img [src]="(image.url | image) | async" />
 * <div [style.background-image]="'url('+( (image.url | image) | async )+')'"></div>  
 * 
 */

export class ImageCache {
    [propName: string]: string | boolean;
}
@Injectable()
export class ImageCacheProvider {
    cache: ImageCache = {};
    constructor() {
    }
}

/**
 * Hinweis: deprecation wurde vorerst aufgehoben
 */
@Injectable()
@Pipe({
    name: 'image',
})
export class ImagePipe implements PipeTransform {
    mockUpEntries = ENTRIES;
    
    constructor(
        private http: HttpClient,
        private environmentService: EnvironmentService,
       // private message: MessageProvider,
        @Optional() private cache: ImageCacheProvider,
    ) { }

    transform(url: string, mockup: string, cache: boolean = false) { // use cache parameter with caution, note, for this to work, ImageCacheProvider MUST be injected to the app.component!!!
        if(EnvironmentService.isMock && typeof mockup!='undefined' && mockup ){
            return this.getMockUpPath(mockup);
        }

        let thisA = this;
        //if(!url) return '';
        if (cache && this.cache && this.cache.cache.hasOwnProperty(url)) {
            return Observable.create(observer => {
                observer.next(this.cache.cache[url]);
            });
        } else {
            return this.http.get(url, {
                headers: new HttpHeaders({ 'Accept': 'image/*, */*' }),
                responseType: 'blob'
            }).pipe(switchMap((blob: Blob) => {
                // return new observable which emits a base64 string when blob is converted to base64
                return Observable.create(observer => {
                    const reader = new FileReader();
                    reader.readAsDataURL(blob); // convert blob to base64
                    reader.onloadend = function () {
                        observer.next(reader.result); // emit the base64 string result (to the actual displaying DOM or async pipe)
                        if (cache && thisA.cache) {
                            thisA.cache.cache[url] = "" + reader.result;
                        }
                        // thisA.message.subject.next({ // also tell potential listeners in the application that it is now loaded (ready to be shown by the user agent)
                        //     imagePipe: {
                        //         url: url,
                        //         success: true,
                        //         data: reader.result
                        //     }
                        // });
                    };
                    reader.onerror = function (error) {
                        console.error('FileReader failed to read blob: onerror raised', error);
                        observer.error(error);
                    };
                    reader.onabort = function (error) {
                        console.error('FileReader failed to read blob: onabort raised', error);
                        observer.error(error);
                    };
                });
            }), catchError((error, caught) => {
                // thisA.message.subject.next({ // also tell potential listeners in the application that it failed to load
                //     imagePipe: {
                //         url: url,
                //         success: false,
                //         error: error,
                //         caught: caught
                //     }
                // });
                return Promise.resolve(url); // just return the original url (will not work, but breaks the deadloop)
            }));
        }
    }


    private getMockUpPath(mockUpName: string): string {
        let mockUp = this.mockUpEntries.find((mockUp: MockUpImage) => mockUp.name === mockUpName);
        
        if(typeof mockUp!='undefined' && mockUp){
            return mockUp.path
        }
        console.log("No mockUp found with " + mockUpName)
        return null;
    }
}

