import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecurePipe } from './secure/secure.pipe';
import { TrustResourceUrlPipe } from './trustResourceUrl/trust-resource-url.pipe';
import { ImagePipe } from './image/image.pipe';

@NgModule({
  declarations: [ SecurePipe, TrustResourceUrlPipe, ImagePipe],
  imports: [
    CommonModule
  ],
  exports: [SecurePipe, TrustResourceUrlPipe, ImagePipe]
})
export class PipesModule { }
