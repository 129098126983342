import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Dashboard } from 'services/hr-cockpit/dashboard';

@Component({
  selector: 'app-menu-hr-cockpit',
  templateUrl: './menu-hr-cockpit.html',
  styleUrls: ['menu-hr-cockpit.scss'],
})
export class MenuHrCockpitComponent {
  @Input() dashboards: Dashboard[];
  @Input() isEditMode: boolean;
  @Input() isNewMode: boolean;
  @Output() switchDashboardEvent: EventEmitter<Dashboard> = new EventEmitter<Dashboard>();
  @Output() toggleEditModeEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleNewDashboardEvent: EventEmitter<void> = new EventEmitter<void>();
  isMenuOpen = false;
  // if dashboards.size >= 5, new Dashboards not allowed
  maxDashboards = 5;


  constructor() {
  }

  doToggleSwitchDashboard(dashboard: Dashboard): void {
    this.switchDashboardEvent.emit(dashboard);
  }

  doToggleNewDashboard(): void {
    this.handleMenuBar();
    this.toggleNewDashboardEvent.emit();
  }

  doToggleEditDashboard(): void {
    this.handleMenuBar();
    this.toggleEditModeEvent.emit();
  }

  handleMenuBar() {
    this.isMenuOpen = !this.isMenuOpen;
  }
}
