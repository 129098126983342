import { TitleButton } from 'entities/buttons';
import { Authorization } from '../../services/authorization/authorization.view';

let authorization = new Authorization();

export const ENTRIES: TitleButton[] = [
  {
    id: "bewerbungen", title: 'Bewerbungen', url: '/menu/bewerbungen', icon: 'person', open: true, active: false, enabled: false, authorization: authorization.menuPageApplications, subPages: [
      { title: 'Email-Bewerbungen', url: '/menu/email', active: false, enabled: false, authorization: authorization.menuPageEmailApplications },
      { title: 'Bewerbungen anlegen - langer text', url: '/menu/anlegen', active: false, enabled: false, authorization: authorization.menuPageNewApplication }]
  },
  { id: "auschreibungen", title: 'Ausschreibungen', url: '/menu/auschreiben', icon: 'list-circle', open: true, active: false, enabled: false, authorization: authorization.menuPageAnnouncement },
  { id: "multiposting", title: 'Multiposting', url: '/menu/multiposting', icon: 'earth', open: true, active: false, enabled: false, authorization: authorization.menuPageMultiposting },
  {
    id: "controlling", title: 'Controlling', url: '/menu/controlling', icon: 'stats-chart', open: true, active: false, enabled: false, authorization: authorization.menuPageControlling, subPages: [
      { id: "reports", title: 'Reports', url: '/menu/reports', active: false, enabled: false, authorization: authorization.menuPageReports }]
  },
  {
    id: "konfiguration", title: 'Konfiguration', url: '/menu/config', icon: 'cog', open: true, active: false, enabled: false, authorization: authorization.menuPageConfiguration, subPages: [
      { id: "templates", title: 'Templates', url: '/menu/templates', active: false, enabled: false, authorization: authorization.menuPageTemplates },
      { id: "einstellungen", title: 'Einstellungen', url: '/menu/items', active: false, enabled: false, authorization: authorization.menuPageSettings },
      { id: "addOns", title: 'Add Ons', url: '/menu/addons', active: false, enabled: false, authorization: authorization.menuPageAddOns }]
  },
  {
    id: "stammdaten", title: 'Stammdaten', url: '/menu/stammdaten', icon: 'cog', open: true, active: false, enabled: false, authorization: authorization.menuPageConfiguration, subPages: []
  },
];
