import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { mergeMap, Observable } from 'rxjs';
import { ApiService } from 'services/api/api.service';
import { CachingService } from 'services/caching/caching.service';

@Injectable({
  providedIn: 'root'
})
export default class TranslateLoaderService extends ApiService implements TranslateLoader {

  constructor(
    protected caching: CachingService,
    protected http: HttpClient
  ) {
    super(caching, http, 'i18n');
  }

  // TODO might use later for caching
  // getTranslation(lang: string): Observable<any> {
  //   return this.texts().pipe(
  //     mergeMap(data => super.httpPost('/texts', lang, data)),
  //   );
  // }
  //
  // private texts(): Observable<Object> {
  //   return this.http.get('assets/de.json');
  // }

  getTranslation(lang: string): Observable<any> {
    return this.http.get(`assets/i18n/${lang}.json`);
  }
}
