import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-widget',
  templateUrl: './new-widget.component.html',
  styleUrls: ['./new-widget.component.scss'],
})
export class NewWidgetComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
