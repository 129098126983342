import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CachingService } from 'services/caching/caching.service';
import { EnvironmentService } from 'services/environment/environment.service';

/**
 * Abstract service zur Kapselung von http request auf api services
 */
export abstract class ApiService {
    private urlPrefix: string;
    public key: string;

    constructor(
        protected cachingService: CachingService,
        protected http: HttpClient,
        protected name: string
    ) {
        this.urlPrefix = EnvironmentService.urlApiPrefix() + '/' + name;
        this.key = name;
    }

    /**
     */
    public getKey(): string {
        return this.key;
    }

    /**
     * Gibt leer-string zurück, falls key nicht gesetzt, andernfalls wird vorne ein "/" angehängt.
     * @param key der zu prüfende Key
     */
    private k(key: any): string {
        return !key ? '' : ('/' + key);
    }

    /**
     * Hängt '/list' an die URL und sendet ein HTTP-GET-Request.
     * @param key der Name der angefragten Entity
     * @param id ID einer Entity
     * @param params String, der als Parameter an das Request gehängt wird
     */
    public list(key?: string, id?: any, params: string = ''): Observable<any> {
        return this.httpGet(this.k(key) + '/list' + this.k(id) + params);
    }

    /**
     * Sendet ein HTTP-GET-Request mit HTTP-Parameter.
     * @param params String, der als Parameter an das Request gehängt wird
     */
    public listParams(params: string, key?: string, id?: any): Observable<any> {
        return this.httpGet(this.k(key) + '/list' + this.k(id) + '?search=' + params);
    }

    /** 
     * Hängt '/list' an die URL und sendet ein HTTP-POST-Request.
     * @param key der Name der angefragten Entity
     */
    public listPost({ key, id, body }: { key?: string, id?: any, body?: any }): Observable<any> {
        return this.httpPost(this.k(key) + '/list', id, body);
    }

    /**
     * Hängt '/get/{id}' an die URL und sendet ein HTTP-GET-Request.
     * @param id die Entity ID
     * @param key der Name der angefragten Entity
     */
    public get(id: any, key?: string): Observable<any> {
        return this.httpGet(this.k(key) + '/get/' + id);
    }

    /**
     * Returns Dynamic Filter options
     * 
     * @param id 
     * @param key 
     */
    public getDynamicFilterOption(id: any, entityId?: any, key?: string): Observable<any> {
        return this.httpGet(this.k(key) + '/get' + this.k(entityId) + '/dynamicfilter/options/' + id);
    }

    /**
     * Hängt '/masterdata' an die URL und sendet ein HTTP-GET-Request.
     * @param key der Name der angefragten Entity
     */
    public getMdata(id?: any, key?: string): Observable<any> {
        return this.httpGet(this.k(key) + '/masterdata' + this.k(id));
    }

    /**
     * Hängt '/delete/{id}' an die URL und sendet ein HTTP-GET-Request.
     * @param id die Entity ID
     * @param key der Name der zu löschenden Entity
     */
    public delete(id: any, key?: string): Observable<any> {
        return this.httpGet(this.k(key) + '/delete/' + id);
    }

    /**
     * Hängt '/save/{id}' an die URL und sendet ein HTTP-POST-Request.
     * @param body der Entity Body, also das was gespeichert wird
     * @param key der Name der zu aktualisierende Entity
     */
    public save(body: any, key?: string): Observable<any> {
        return this.httpPost(this.k(key) + '/save', null, body);
    }

    /**
     * Sendet einen reinen HTTP-GET Request an die gegebene URL.
     * Alle Requests werden anhand der URL (key) gecached und können zu einem späteren
     * Zeitpunkt wieder vom Cache abgerufen werden (Standard = kein Cache).
     */
    public httpGet(url: string, useCache: boolean = false, options?: any): Observable<any> {
        const key = this.urlPrefix + url;
        const result: Observable<any> = useCache && this.cachingService.contains(key) ? this.cachingService.load(key) : this.http.get(key, options);
        this.cachingService.store(key, result);
        return result;
    }

    /**
     * Sendet einen reinen HTTP-POST Request an die gegebene URL.
     */
    public httpPost(url: string, id?: any, body?: any, options?: any): Observable<any> {
        return this.http.post(this.urlPrefix + url + this.k(id), body, options);
    }

    /**
     * Filter eine Liste an objekten / strings auf einen wert
     */
    public filter<T>(list: Array<T>, searchTerm: string): Array<T> {
        if (!searchTerm || !list) {
            return list;
        } else {
            return list.filter(val => {
                if (!val) {
                    return false;
                }
                return this.objectHasValue(val, searchTerm);
            });
        }
    }

    private objectHasValue(obj: any, searchTerm: string): boolean {
        const contains = (o: any) => ("" + o).toLocaleLowerCase().includes(searchTerm);
        if (contains(obj)) {
            return true;
        }

        for (var key in obj) {
            const o = obj[key];
            if (contains(o)) {
                return true;

            } else if (typeof o === 'object') {
                if (this.objectHasValue(o, searchTerm)) {
                    return true;
                }
            }
        }
        return false;
    }
}