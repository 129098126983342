import { Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

export interface LinkOption {
  index: number;
  title: string;
  url: string;
  src: string;
  isShown: boolean;
}

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss'],
})
export class LinksComponent  implements OnInit, OnChanges {
  @Input() settings;
  @Input() sizeChange;
  @Input() id;
  @Input() widgetWidth;
  @Input() widgetHeight;

  defaultLinks: LinkOption[] = [
    {
      index: 0,
      title: 'Analytics',
      url: 'https://www.google.com',
      src: './assets/images/links-widget/analytics.png',
      isShown: true,
    },
    {
      index: 1,
      title: 'Portalverwaltung',
      url: 'https://www.google.com',
      src: './assets/images/links-widget/portalverwaltung.png',
      isShown: true,
    },
    {
      index: 2,
      title: 'Performanceboard',
      url: 'https://www.google.com',
      src: './assets/images/links-widget/performanceboard.png',
      isShown: true,
    },
    {
      index: 3,
      title: 'Stellencockpit',
      url: 'https://www.google.com',
      src: './assets/images/links-widget/stellencockpit.png',
      isShown: true,
    },
  ];
  individualLinks: LinkOption[] = [];

  constructor() { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.settings || !changes.settings.previousValue) {
      return;
    }
    this.toggleDefaultLinks(changes.settings.currentValue.linkSelection);
    this.updateIndividualLinks(changes.settings.currentValue.individualLinks);
  }

  toggleDefaultLinks(linkTitles: string[]) {
    if (!linkTitles) {
      return;
    }
    linkTitles.map((linkTitle: string) => linkTitle.toLowerCase());
    this.defaultLinks.forEach((link: LinkOption) => {
      link.isShown = linkTitles.includes(link.title.toLowerCase());
    });
  }

  updateIndividualLinks(links: LinkOption[]) {
    if (!links || links.length === 0) {
      return;
    }

    for (const link of links) {
      while (!this.individualLinks[link.index]) {
        this.individualLinks.push({} as LinkOption);
      }

      this.individualLinks[link.index] = {
        index: link.index,
        title: link.title,
        url: link.url,
        src: link.src,
        isShown: link.isShown
      };

      if (!link.isShown) {
        this.individualLinks.splice(link.index, 1);
      }
    }
  }
}
