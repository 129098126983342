import { Injectable } from '@angular/core';
import { HttpRequest, HttpHeaders } from '@angular/common/http';

import { environment } from 'environments/environment';
import { ConstantVariable } from 'constant-variable';
import { TestUrlMapping } from 'constant-variable';

export class ApiReplaceResult {
  replaced: boolean;
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  constructor(
    // injects here
  ) {
    // nothing to do here
  }

  // --- Public Methods --- //

  public static version(): string {
    return environment.version;
  }

  /**
   * Return true, if the current environment is "prod".
   */
  public static isProd(): boolean {
    return environment && environment.production;
  }

  /**
   * Return true, if the current environment is "dev".
   */
  public static isMock(): boolean {
    return environment && environment.mock;
  }

  public static urlApiPrefix(): string {
    return ConstantVariable.apiPrefix;
  }

  public static urlApi(): string {
    return environment.apiUrl;
  }

  /**
   * @param url
   */
  public static doConvertUrl(url: any): any {
    let r = this.doMock(url);
    r = this.doApiReplace(url).url;
    return r;
  }

  public static doMock(url: string, force: boolean = false): string {
    let requestUrl = url;

    if (force || this.isMock()) {
      if (TestUrlMapping.has(requestUrl)) {
        requestUrl = TestUrlMapping.get(requestUrl);
      } else {
        // check startswith
        TestUrlMapping.forEach((value, key) => {
          if (requestUrl.startsWith(key)) {
            requestUrl = value;
          }
        });
      }
    }
    return requestUrl;
  }

  public static doApiReplace(requestUrl: string): ApiReplaceResult {
    const r = <ApiReplaceResult>{
      url: requestUrl
    };

    if (requestUrl && requestUrl.startsWith(this.urlApiPrefix() + '/')) {
      r.url = requestUrl.replace(this.urlApiPrefix() + '/', this.urlApi() + '/');
      r.replaced = r.url !== requestUrl;
    }
    return r;
  }
}
