import { IconButton } from 'entities/buttons';
import { Authorization } from '../../services/authorization/authorization.view';

let authorization = new Authorization();

export const ENTRIES: IconButton[] = [
    { icon: 'reload-circle', badge: false, enabled: false, function: "systemUpdate", css: "btn sysupdate", authorization: authorization.dashboardSystemUpdate },
    { icon: 'chatbubbles', badge: true, enabled: false, function: "chat", css: "btn", authorization: authorization.dashboardBtnChat },
    { icon: 'file-tray', badge: false, enabled: false, function: "lastActions", css: "btn", authorization: authorization.dashboardBtnLastActions },
    { icon: 'information-circle', badge: true, enabled: false, function: "appointment", css: "btn", authorization: authorization.dashboardBtnAppointment },
    { icon: 'bulb', badge: false, enabled: false, function: "help", css: "btn", authorization: authorization.dashboardBtnHelp },
];
