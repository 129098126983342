import { Injectable } from '@angular/core';

// TODO description, what this does
@Injectable({
    providedIn: 'root'
})
export class Authorization {
    // menu
    menuPageApplications: String = 'menu.page.applications';
    menuPageEmailApplications: String = 'menu.page.emailapplications';
    menuPageNewApplication: String = 'menu.page.newApplication';
    menuPageAnnouncement: String = 'menu.page.announcement';
    menuPageMultiposting: String = 'menu.page.multiposting';
    menuPageControlling: String = 'menu.page.controlling';
    menuPageReports: String = 'menu.page.reports';
    menuPageConfiguration: String = 'menu.page.configuration';
    menuPageTemplates: String = 'menu.page.templates'
    menuPageSettings: String = 'menu.page.settings';
    menuPageAddOns: String = 'menu.page.addons';

    // dashboard 
    dashboardSearchBar: String = 'dashboard.searchbar';
    dashboardSystemUpdate: String = 'dashboard.systemupdate';

    dashboardBtnChat: String = 'dashboard.btn.chat';
    dashboardBtnLastActions: String = 'dashboard.btn.lastactions';
    dashboardBtnAppointment: String = 'dashboard.btn.appointment';
    dashboardBtnHelp: String = 'dashboard.btn.help';
    dashboardAccount: String = 'dashboard.account';
}
