// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  flex-direction: column;
  height: 100%;
  width: 100%;
  display: flex;
}
.card__menu-icon {
  color: var(--hr-highlight-color);
  font-size: 2rem;
  position: absolute;
  right: 0;
  margin-top: calc(-1 * var(--grid-padding) - 4px);
}
.card__menu-icon--invisible {
  visibility: hidden;
}
.card__content, .card__header {
  padding: 0;
}
.card__content {
  height: 100%;
}
.card__ion {
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  flex-grow: 1;
  overflow: visible;
  margin: var(--grid-margin);
  padding: var(--grid-padding);
}
.card__widget-menu {
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  flex-grow: 1;
  overflow: visible;
  margin: calc(-1 * (var(--grid-margin) + var(--grid-padding) + 10px)) var(--grid-margin) var(--grid-margin) var(--grid-margin);
  padding: var(--grid-padding);
  display: none;
}
.card__widget-menu--visible {
  display: block;
  z-index: 1;
}
.card__title {
  margin-top: unset;
}
.card__title-span {
  text-transform: uppercase;
  color: var(--text-color-headline);
  font-weight: bold;
}
.card__title-span--highlight {
  color: var(--hr-highlight-color);
}
.card--highlight {
  box-shadow: var(--highlight-box-shadow), var(--box-shadow);
}`, "",{"version":3,"sources":["webpack://./src/app/components/card/card.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;AACF;AACE;EACE,gCAAA;EACA,eAAA;EACA,kBAAA;EACA,QAAA;EACA,gDAAA;AACJ;AACI;EACE,kBAAA;AACN;AAGE;EACE,UAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,mCAAA;EACA,6BAAA;EACA,YAAA;EACA,iBAAA;EACA,0BAAA;EACA,4BAAA;AAHJ;AAME;EACE,mCAAA;EACA,6BAAA;EACA,YAAA;EACA,iBAAA;EACA,6HAAA;EAEA,4BAAA;EACA,aAAA;AALJ;AAOI;EACE,cAAA;EACA,UAAA;AALN;AASE;EACE,iBAAA;AAPJ;AAUE;EACE,yBAAA;EACA,iCAAA;EACA,iBAAA;AARJ;AAUI;EACE,gCAAA;AARN;AAYE;EACE,0DAAA;AAVJ","sourcesContent":[".card {\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n  display: flex;\n\n  &__menu-icon {\n    color: var(--hr-highlight-color);\n    font-size: 2rem;\n    position: absolute;\n    right: 0;\n    margin-top: calc(-1 * var(--grid-padding) - 4px);\n\n    &--invisible {\n      visibility: hidden;\n    }\n  }\n\n  &__content, &__header {\n    padding: 0;\n  }\n\n  &__content {\n    height: 100%;\n  }\n\n  &__ion {\n    border-radius: var(--border-radius);\n    box-shadow: var(--box-shadow);\n    flex-grow: 1;\n    overflow: visible;\n    margin: var(--grid-margin);\n    padding: var(--grid-padding);\n  }\n\n  &__widget-menu {\n    border-radius: var(--border-radius);\n    box-shadow: var(--box-shadow);\n    flex-grow: 1;\n    overflow: visible;\n    margin: calc(-1 * (var(--grid-margin) + var(--grid-padding) + 10px)) var(--grid-margin) var(--grid-margin) var(--grid-margin);\n\n    padding: var(--grid-padding);\n    display: none;\n\n    &--visible {\n      display: block;\n      z-index: 1;\n    }\n  }\n\n  &__title {\n    margin-top: unset;\n  }\n\n  &__title-span {\n    text-transform: uppercase;\n    color: var(--text-color-headline);\n    font-weight: bold; //tbd\n\n    &--highlight {\n      color: var(--hr-highlight-color);\n    }\n  }\n\n  &--highlight {\n    box-shadow: var(--highlight-box-shadow), var(--box-shadow);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
