import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NewsItemComponent } from 'components/hr-cockpit/widgets/news/news-item/news-item.component';

@Component({
  selector: 'app-news',
  templateUrl: './news-container.component.html',
  styleUrls: ['./news-container.component.scss'],
})
export class NewsContainerComponent implements OnInit, AfterViewInit {
  @Input() settings;
  @Input() sizeChange;
  @Input() id;
  @Input() widgetWidth;
  @Input() widgetHeight;

  @ViewChild('ionCard', { read: ElementRef }) ionCard: ElementRef;

  title: string;
  img: string;
  text: string;
  date: string;
  link: string;
  newsItemIndexArray: number[];
  newsItemWidth = 295;
  slidesPerView = 1;

  observer = new ResizeObserver(entries => {
    entries.forEach(entry => {
      this.slidesPerView = Math.max(1, Math.floor(entry.contentRect.width / this.newsItemWidth));
    });
  });

  protected readonly newsItemComponent = NewsItemComponent;

  constructor() { }

  ngOnInit() {
    if (this.settings.content === undefined) {
      this.setPlaceholderContent();
    }
    this.newsItemIndexArray = Array.from({length: this.settings.content.length}, (v, k) => k) as number[];
  }

  ngAfterViewInit() {
    this.observer.observe(this.ionCard.nativeElement);
  }

  setPlaceholderContent() {
    this.settings.content = [];
    this.settings.content.push({
      title: 'Title',
      img: 'https://via.placeholder.com/150',
      text: 'Text',
      date: 'Date',
      link: 'Link'
    });
  }

  getInputs(index: number) {
    return {
      title: this.settings.content[index].title,
      img: this.settings.content[index].img,
      text: this.settings.content[index].text,
      date: this.settings.content[index].date,
      link: this.settings.content[index].link
    };
  }
}
