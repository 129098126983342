import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ConfigService } from 'services/config/config.service';
import { Config } from 'entities/config';
import { Dashboard } from 'services/hr-cockpit/dashboard';
import { Theme } from 'services/hr-cockpit/theme';
import { AlertService } from 'services/hr-cockpit/alert.service';
import { getDeleteDashboardAlert, getResetDashboardAlert, getSaveDashboardAlert } from 'services/hr-cockpit/alert.const';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hr-cockpit-header',
  templateUrl: './header-hr-cockpit.component.html',
  styleUrls: ['./header-hr-cockpit.component.scss']
})
export class HeaderHrCockpitComponent implements OnInit, OnChanges {
  @Input() currentDashboard: Dashboard;
  @Input() dashboards: Dashboard[];
  @Input() isEditMode: boolean;
  @Input() isNewDashboard: boolean;
  @Output() toggleSaveDashboardEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() toggleResetDashboardEvent: EventEmitter<void> = new EventEmitter();
  @Output() toggleDeleteDashboardEvent: EventEmitter<void> = new EventEmitter();
  @Output() toggleChangeThemeEvent: EventEmitter<Theme> = new EventEmitter<Theme>();
  @Output() toggleNewSectionEvent: EventEmitter<void> = new EventEmitter();
  config: Config;
  themes: Theme[] = ['theme-one', 'theme-two', 'theme-three'];
  buttons = [
    {label: 'Sektion hinzufügen', action: this.doToggleNewSection.bind(this)},
  ];
  inputPlaceholder = 'Dashboard Titel';
  boardTitle: string;
  boardTheme: Theme;

  constructor(private configService: ConfigService,
              private alertService: AlertService,
              private translate: TranslateService) {
  }

  ngOnInit() {
    this.configService.loadConfig().subscribe(c => {
      this.config = c;
    });
  }

  ngOnChanges() {
    this.setBoardValues(this.currentDashboard?.theme, this.currentDashboard?.title);
  }

  getTitle() {
    if (this.boardTitle === null || this.boardTitle === undefined) {
      this.setBoardValues(this.currentDashboard?.theme, this.currentDashboard?.title);
    }
    return this.boardTitle;
  }

  setTitle(event: CustomEvent) {
    this.boardTitle = event.detail.value;
  }

  setTheme(newTheme: Theme) {
    this.boardTheme = newTheme;
    this.toggleChangeThemeEvent.emit(newTheme);
  }

  setBoardValues(theme?: Theme, title?: string) {
    this.boardTheme = theme;
    this.boardTitle = title;
  }

  async doToggleSaveDashboard() {
    if (await this.alertService.getAlert(getSaveDashboardAlert(this.translate), this.boardTheme)) {
      this.currentDashboard.theme = this.boardTheme;
      this.currentDashboard.title = this.boardTitle;
      this.toggleSaveDashboardEvent.emit();
    }
  }

  async doToggleResetDashboard() {
    if (await this.alertService.getAlert(getResetDashboardAlert(this.translate), this.boardTheme)) {
      this.setBoardValues(this.currentDashboard.theme, this.currentDashboard.title);
      this.toggleResetDashboardEvent.emit();
    }
  }

  async doToggleDeleteDashboard() {
    if (await this.alertService.getAlert(getDeleteDashboardAlert(this.translate), this.boardTheme)) {
      this.toggleDeleteDashboardEvent.emit();
    }
  }

  doToggleNewSection() {
    this.toggleNewSectionEvent.emit();
  }

  doToggleAccountSettings(): void {
    // TODO
    console.log('ACCOUNT SETTINGS', this.config);
  }

  doToggleLogout(): void {
    // TODO
    console.log('LOGOUT');
  }
}
