import {CommonModule, NgOptimizedImage} from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { ContentComponent } from './content/content.component';
import { MenuComponent } from './menu/menu.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AnonymousPrivacyComponent } from './anonymous-privacy/anonymous-privacy.component';
import { HeaderComponent } from './header/header.component';
import { CardComponent } from './card/card.component';
import { NewWidgetComponent } from 'components/hr-cockpit/widgets/new-widget/new-widget.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { PipesModule } from 'pipes/pipes.module';
import { TestComponent } from './test/test.component'; // TestComponent should be deleted when first real Widget-Component is implemented
import { HeaderHrCockpitComponent } from 'components/hr-cockpit/header-hr-cockpit/header-hr-cockpit.component';
import { MenuHrCockpitComponent } from 'components/hr-cockpit/menu-hr-cockpit/menu-hr-cockpit';
import { KpiComponent } from './hr-cockpit/widgets/kpi/kpi.component';
import { JobApplicationComponent } from './hr-cockpit/widgets/job-application/job-application.component';
import { WidgetMenuComponent } from './hr-cockpit/widgets/widget-menu/widget-menu.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AdListComponent } from './hr-cockpit/widgets/ad-list/ad-list.component';
import { JobListComponent } from 'components/hr-cockpit/widgets/job-list/job-list.component';
import { NewsContainerComponent } from 'components/hr-cockpit/widgets/news/news-container/news-container.component';
import { NewsItemComponent } from 'components/hr-cockpit/widgets/news/news-item/news-item.component';
import { LinksComponent } from 'components/hr-cockpit/widgets/links/links.component';
import { InvoiceOverviewComponent } from 'components/hr-cockpit/widgets/invoice-overview/invoice-overview.component';

@NgModule({
  declarations: [
    ContentComponent,
    SidebarComponent,
    MenuComponent,
    AnonymousPrivacyComponent,
    HeaderComponent,
    CardComponent,
    NewWidgetComponent,
    TestComponent,
    MenuHrCockpitComponent,
    KpiComponent,
    JobApplicationComponent,
    WidgetMenuComponent,
    HeaderHrCockpitComponent,
    AdListComponent,
    JobListComponent,
    LinksComponent,
    NewsContainerComponent,
    NewsItemComponent,
    InvoiceOverviewComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    PipesModule,
    NgOptimizedImage,
    ReactiveFormsModule
  ],
  exports: [
    ContentComponent,
    SidebarComponent,
    MenuComponent,
    AnonymousPrivacyComponent,
    HeaderComponent,
    CardComponent,
    NewWidgetComponent,
    TestComponent,
    MenuHrCockpitComponent,
    KpiComponent,
    JobApplicationComponent,
    WidgetMenuComponent,
    HeaderHrCockpitComponent,
    AdListComponent,
    JobListComponent,
    LinksComponent,
    NewsContainerComponent,
    NewsItemComponent,
    InvoiceOverviewComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
})
export class CustomComponentsModule {
}
