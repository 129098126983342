import { Injectable } from '@angular/core';
import { Config } from 'entities/config';

export const enum typeOfAuthorization {
  ACTION, SINGLE_VIEW, MULTI_VIEW
}

// TODO issue return types anpassen
@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor() { }

  public isAuthorized(type: typeOfAuthorization, conf: Config, value: any): any {
    if (!conf) {
      return false;
    }

    switch (type) {
      case typeOfAuthorization.ACTION: {
        // TODO 
        break;
      }
      case typeOfAuthorization.SINGLE_VIEW: {
        return this.getAuthorization(value);
      }
      case typeOfAuthorization.MULTI_VIEW: {
        return this.isPAGEAuthorized(value);
      }
    }
  }

  private isPAGEAuthorized(value: any) {
    if (Array.isArray(value) && value.length) {
      value.forEach(page => {
        page.enabled = this.getAuthorization(page.authorization);

        if (Array.isArray(page.subPages) && page.subPages.length) {
          page.subPages.forEach(subPage => {
            subPage.enabled = this.getAuthorization(subPage.authorization);
          });
        }
      });
    }
    return value;
  }

  // TODO API CALL 
  private getAuthorization(btn: String): Boolean {
    return true;
  }
}
