// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.links {
  display: block;
  font-size: 8pt;
  padding-left: 20px;
  padding-top: 5px;
}
div.links ion-router-link {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/anonymous-privacy/anonymous-privacy.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,cAAA;EACA,kBAAA;EACA,gBAAA;AACJ;AACI;EACI,eAAA;AACR","sourcesContent":["div.links {\n    display: block;\n    font-size: 8pt;\n    padding-left: 20px;\n    padding-top: 5px;\n\n    ion-router-link {\n        cursor: pointer;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
