import { Injectable } from '@angular/core';
import { EnvironmentService } from 'services/environment/environment.service';

@Injectable({
  providedIn: 'root'
})
// based on http://gitlab.telum.biz/heroes/consent/-/blob/master/resource/he-consent.js
export class ConsentService {
  queue = [];
  opts = {
    debug: false,
    src: EnvironmentService.isMock() ? 'assets/test/libs/consent.js' : '/rest/track/he-consent',
    ready: null,
    zindex: 1,
    opts: {
      cookie: {
        lang: 'de',
        elements: { lang: null }
      }
    }
  };
  consentController = null;

  constructor() {
    this.appendScript(this.opts.src, this.createConsent.bind(this));

    if (this.opts.zindex) {
      this.appendStyle('.he-cc-container { z-index: ' + this.opts.zindex + ' } ');
    }
  }

  public cookiePolicy() {
    this.consentController.toggle();
  }

  public changeLanguage(language) {
    this.consentController.changeLang(language);
  }

  private appendScript(src, callback) {
    var script = document.createElement('script');
    script.onload = callback;
    script.src = src;
    document.head.appendChild(script);
  }

  private appendStyle(css) {
    var style = document.createElement('style');
    style.innerHTML = css;
    document.head.appendChild(style);
  }



  private createConsent() {
    if (!!this.opts) {
      if (!!this.consentController) {
        this.consentController.destroy();
      }

      this.consentController = new window["HeroesConsent"](this.opts.opts);
      if (this.queue.length > 0) {
        this.queue.forEach(q => {
          this.consentController.register(q.name, q.callback);
        });
        this.queue = [];
      }
      if (typeof this.opts.ready === 'function') {
        this.opts.ready();
      }
    }
  }
}
