import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { OAuthStorage } from 'angular-oauth2-oidc';
import { authConfig } from 'constant-variable';

export function StorageFactory(storageService: StorageService): OAuthStorage {
  return storageService;
}

@Injectable({
  providedIn: 'root'
})
export class StorageService implements OAuthStorage {
  readonly STORAGE_PREFIX = authConfig.clientId + '_';
  private oauthStorage: OAuthStorage;

  constructor(
    private storage: Storage
  ) {
    this.oauthStorage = localStorage; // alternativ bzw. default ist "sessionStorage"
    storage.create();
  }

  /**
   * Speichert ein Key-Value Paar permanent.
   * @param key der Key, anhand dessen der Wert später geladen werden kann
   * @param value der zu speichernde Wert
   */
  public store(key: string, value: any): Promise<any> {
    return this.storage.set(this.extendKey(key), value);
  }

  /**
   * Lädt ein Promise-Objekt anhand eines Keys aus dem permanenten Speicher.
   * @param key der Key zur Identifikation
   */
  public load(key: string): Promise<any> {
    return this.storage.get(this.extendKey(key));
  }

  /**
   * Löscht ein Key-Value Paar anhand eines Keys aus dem permanenten Speicher.
   * @param key der Key zur Identifikation
   */
  public remove(key: string): Promise<any> {
    return this.storage.remove(this.extendKey(key));
  }

  /**
   * Löscht alle Daten aus dem permanenten Speicher.
   */
  public clear(): Promise<any> {
    return this.storage.clear();
  }

  /**
   * Gibt den KEY mit prefix für die APP zurück
   */
  extendKey(key: string): string {
    if (!key) {
      return key;
    }
    if (!key.startsWith(this.STORAGE_PREFIX)) {
      return this.STORAGE_PREFIX + key;
    }
    return key;
  }

  getItem(key: string): string {
    return this.oauthStorage.getItem(this.extendKey(key));
  }

  removeItem(key: string): void {
    return this.oauthStorage.removeItem(this.extendKey(key));
  }

  setItem(key: string, data: string): void {
    return this.oauthStorage.setItem(this.extendKey(key), data);
  }
}
