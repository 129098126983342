import { Injectable } from '@angular/core';
import { Alert } from 'services/hr-cockpit/alert';
import { AlertController } from '@ionic/angular';
import { Theme } from 'services/hr-cockpit/theme';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  alert: HTMLIonAlertElement;
  alertCtrl: AlertController;

  constructor() {
    this.alertCtrl = new AlertController();
  }

  async getAlert(alertConfig: Alert, theme: Theme): Promise<boolean> {
    this.alert = await this.alertCtrl.create({
      header: alertConfig.header,
      message: alertConfig.message,
      buttons: alertConfig.buttons,
      cssClass: [theme, 'hr-alert'],
      backdropDismiss: false,
    });

    await this.alert.present();
    const {role} = await this.alert.onDidDismiss();
    return role === 'confirm';
  }
}
