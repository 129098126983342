import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'guards/auth.guard';

const routes: Routes = [
  {
    path: 'hr-cockpit',
    redirectTo: 'hr-cockpit',
    pathMatch: 'full'
  },
  // default path
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  // public

  // protected
  {
    path: 'dashboard', canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'menu/:page', canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'menu/personalized/:page', canActivate: [AuthGuard],
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/public/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'password-forgotten',
    loadChildren: () => import('./pages/password-forgotten/password-forgotten.module').then( m => m.PasswordForgottenPageModule)
  },
  {
    path: 'password-reset/:key',
    loadChildren: () => import('./pages/password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'hr-cockpit',
    loadChildren: () => import('./pages/hr-cockpit/hr-cockpit.module').then( m => m.HrCockpitPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {}