import { Component, OnInit } from '@angular/core';
import { Config } from 'entities/config';
import { AuthorizationService, typeOfAuthorization, AuthenticationService } from 'app-services';
import { TranslateService } from '@ngx-translate/core';
import { ENTRIES } from './header.entries';
import { Authorization } from '../../services/authorization/authorization.view';
import { ConfigService } from 'services/config/config.service';
import { IconButton } from 'entities/buttons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  private searchTerm: String = '';
  private searchBarPlaceholder: String = this.translate.instant("global.header.searchbar");
  private config: Config = null;
  private isSearchbarAllowed: Boolean = false;
  private isSystemUpdateAllowed: Boolean = false;
  private btnRow: IconButton[];

  constructor(
    private authorizationService: AuthorizationService,
    private authorization: Authorization,
    private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private configService: ConfigService,
  ) { }

  ngOnInit() {
    this.configService.loadConfig().subscribe(c => {
      this.config = c;

      this.btnRow = ENTRIES;
      this.btnRow = this.setBadgeNotifcation(this.btnRow);
      this.btnRow = this.authorizationService.isAuthorized(typeOfAuthorization.MULTI_VIEW, this.config, this.btnRow);

      this.isSearchbarAllowed = this.authorizationService.isAuthorized(typeOfAuthorization.SINGLE_VIEW, this.config, this.authorization.dashboardSearchBar);
      this.isSystemUpdateAllowed = this.authorizationService.isAuthorized(typeOfAuthorization.SINGLE_VIEW, this.config, this.authorization.dashboardSystemUpdate);
    });
  }

  private setBadgeNotifcation(btnRow: IconButton[]): IconButton[] {
    return btnRow;
  }

  private filterItems(): void {
    console.log(this.searchTerm);
  }

  private doToggleSystemUpdate(): void {
    console.log("Wir brauchen neues Bier!");
  }

  private doToggleChat(): void {
    console.log("Lass uns über Bier reden!");
  }

  private doToggleLastActions(): void {
    console.log("Was waren unsere letzten zehn Biere.");
  }

  private doToggleAppointment(): void {
    console.log("Wann gibt es das nächste Bier?");
  }

  private doToggleHelp(): void {
    console.log("Ich brauch Hilfe... aber nicht beim Bier!")
  }

  private doToggleLogout(): void {
    this.authenticationService.logout();
  }

  private doToggleAccountSettings(): void {
    console.log("Hier können wir einstellen ob wir lieber Weißbier oder Helles trinken...")
  }

  private doToggle(value: String): void {
    if (value === 'appointment') {
      this.doToggleAppointment();
    }
    if (value === 'chat') {
      this.doToggleChat();
    }
    if (value === 'help') {
      this.doToggleHelp();
    }
    if (value === 'lastActions') {
      this.doToggleLastActions();
    }
    if (value === 'systemUpdate') {
      this.doToggleSystemUpdate();
    }
  }
}
