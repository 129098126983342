import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig, ConstantVariable } from 'constant-variable';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  authenticationState = new BehaviorSubject(null);

  private resetReturn = false;

  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private httpClient: HttpClient
  ) {
  }

  initializeApp() {
    this.authenticationState.subscribe(state => {
      if (state === ConstantVariable.loginState.LOGOUT) {
        const returnUrl = this.resetReturn ? '' : this.router.routerState.snapshot.url;
        this.resetReturn = false;
        console.debug('AuthenticationService logout, redirecting to login:', returnUrl);
        this.router.navigate(['login'], {
          queryParams: {
            returnUrl: returnUrl
          }
        });
      }
    });

    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setupAutomaticSilentRefresh();
  }

  login(username, password): void {
    this.oauthService.fetchTokenUsingPasswordFlow(username, password).then(
      (data) => {
        this.authenticationState.next(ConstantVariable.loginState.LOGIN);
      },
      (err: HttpErrorResponse) => {
        // simple logging, but you can do a lot more, see below
        console.debug('login error:', err);
        this.authenticationState.next(ConstantVariable.loginState.LOGOUT);
      }
    );
  }

  logout(resetReturn = true) {
    const _logout = () => {
      this.oauthService.logOut();
      this.authenticationState.next(ConstantVariable.loginState.LOGOUT);
    };
    this.resetReturn = resetReturn;
    this.httpClient.get(ConstantVariable.apiPrefix + '/logout').subscribe(d => _logout(), e => _logout());
  }

  isAuthenticated() {
    return this.oauthService.hasValidAccessToken();
  }

  /**
   * gibt den Header-Wert für "Authorization" zurück
   */
  authorization() {
    return "Bearer " + this.oauthService.getAccessToken();
  }

}