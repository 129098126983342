// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-widget {
  text-align: center;
  padding-top: 50%;
}
.new-widget__icon {
  color: var(--hr-highlight-color);
  cursor: pointer;
  font-size: 5rem;
}
.new-widget__subline {
  color: var(--hr-highlight-color);
  font-size: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/hr-cockpit/widgets/new-widget/new-widget.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,gBAAA;AACF;AACE;EACE,gCAAA;EACA,eAAA;EACA,eAAA;AACJ;AAEE;EACE,gCAAA;EACA,iBAAA;AAAJ","sourcesContent":[".new-widget {\n  text-align: center;\n  padding-top: 50%;\n\n  &__icon {\n    color: var(--hr-highlight-color);\n    cursor: pointer;\n    font-size: 5rem;\n  }\n\n  &__subline {\n    color: var(--hr-highlight-color);\n    font-size: 1.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
