import { Component, OnInit, AfterViewInit, AfterContentChecked } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MenuController } from '@ionic/angular';

import { AuthenticationService, typeOfAuthorization } from 'app-services';
import { Config } from 'entities/config';
import { ENTRIES } from './menu.entries';
//import { ConfigService } from 'services/config/config.service';
import { StorageService } from 'services/storage/storage.service';
import { ConstantVariable } from 'constant-variable';
import { AuthorizationService } from 'app-services';
import { ConfigService } from 'services/config/config.service';
import { ConsentService } from 'services/consent/consent.service';
import { TitleButton } from 'entities/buttons';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

    menuSplitEnabled: boolean = true;
    authenticated: boolean;
    config: Config;

    private menuPages: TitleButton[] = ENTRIES;
    private collapsedMenu: Boolean = false;

    private storageKey = 'menu.splitEnabled';
    private storageKeyCollapsed = "menu.collapsed";

    constructor(
        private router: Router,
        private menuCtrl: MenuController,
        private authService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private configService: ConfigService,
        private storageService: StorageService,
        public consentService: ConsentService // used in view
    ) {

        this.menuSplitEnabled = true;

        this.router.events.subscribe(eve => {
            if (eve instanceof NavigationEnd) {
                this.menuCtrl.close();
            }
        });

        this.authService.authenticationState.subscribe(state => {
            if (state === ConstantVariable.loginState.LOGIN) {
                this.doLoadConfig(true);
            } else {
                this.authenticated = false;
            }
        });
    }

    ngOnInit() {
        this.storageService.load(this.storageKey).then(value => {
            this.menuSplitEnabled = !(value === 'false');
        });

        this.storageService.load(this.storageKeyCollapsed).then(value => {
            this.collapsedMenu = !(value === 'false');
        });

        this.doLoadConfig();
    }

    private doLoadConfig(force: boolean = false) {
        // TODO
        this.authenticated = this.authService.isAuthenticated();

        // TODO
        this.configService.loadConfig(force).subscribe(config => {
            this.config = config;
            this.mergePersonalizedMenus();
            this.authorizationService.isAuthorized(typeOfAuthorization.MULTI_VIEW, this.config, ENTRIES);
            this.setMenuVisibility();
            this.setExternalLinks();
        });
    }

    private mergePersonalizedMenus(): void {
        if (!!this.config.personalizedMenuItems) {
            this.config.personalizedMenuItems.forEach(pm => {
                const parentItem = ENTRIES.find(e => e.id === pm.parentMenuItem);
                parentItem?.subPages?.push({
                    id: pm.id, title: pm.title, url: pm.url, active: false, enabled: false, authorization: parentItem.authorization
                }
                )
            });
        }
    }

    public doToggleMenu(): void {
        this.menuSplitEnabled = !this.menuSplitEnabled;
        this.storageService.store(this.storageKey, '' + this.menuSplitEnabled);
    }

    public setMenuVisibility(): void {
        // TODO
        /*const notSet = !this.config.disabledMenus || (this.config.disabledMenus && this.config.disabledMenus.length < 1);
        this.pages.forEach(page => page.enabled = notSet || false === (this.config.disabledMenus.indexOf(page.key) > -1));*/
    }

    public setExternalLinks(): void {
        // TODO
        /*
        const helpPage = this.pages.find(p => p.title === 'page.help.title');
        if (helpPage && this.config.helpPageUrl) {
            helpPage.url = this.config.helpPageUrl;
        }
        const systemPage = this.pages.find(p => p.title === 'page.system.title');
        if (systemPage && this.config.systemPageUrl) {
            systemPage.url = this.config.systemPageUrl;
        }*/
    }

    public isExternal(link: string): boolean {
        return link.startsWith('http');
    }

    public logout(): void {
        this.authService.logout();
    }

    private doMinimizeMenu(): void {
        this.collapsedMenu = true;
        this.storageService.store(this.storageKeyCollapsed, '' + this.collapsedMenu);
    }

    private doMaximizeMenu(): void {
        this.collapsedMenu = false;
        this.storageService.store(this.storageKeyCollapsed, '' + this.collapsedMenu);
    }
}

