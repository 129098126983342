// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fabmenu {
  position: absolute;
  top: -0.4rem;
  left: 2.5rem;
}
.fabmenu__close-menu-icon {
  cursor: pointer;
  margin-top: -8px;
  margin-bottom: -14px;
  font-size: 2rem;
  color: white;
}
.fabmenu__close-menu-label {
  margin-top: -5px;
  padding-bottom: 0;
}
.fabmenu__icon {
  font-size: 2rem;
  color: white;
}
.fabmenu__label {
  width: 50px;
  cursor: pointer;
}
.fabmenu__label--nopointer {
  cursor: initial;
}
.fabmenu__icontitle {
  color: white;
  font-size: 0.6rem;
  line-height: 80%;
}
.fabmenu__list {
  background-image: var(--linear-gradient-color);
  border-radius: 15px;
  padding: 5px 4px 0 4px;
}
.fabmenu--inactive {
  background-image: linear-gradient(#ecebeb, #a4a1a1);
}
.fabmenu--noclickable {
  pointer-events: none;
}
.fabmenu--close {
  display: none;
  margin-bottom: 0;
}
.fabmenu__max_dashboards {
  pointer-events: none;
  opacity: 70%;
}
.fabmenu__space {
  margin-bottom: 10px;
}

.herosLogo {
  height: 25px;
  overflow: initial;
}`, "",{"version":3,"sources":["webpack://./src/app/components/hr-cockpit/menu-hr-cockpit/menu-hr-cockpit.scss"],"names":[],"mappings":"AAGA;EACE,kBAAA;EACA,YAAA;EACA,YAAA;AAFF;AAIE;EACE,eAAA;EACA,gBAAA;EACA,oBAAA;EACA,eAAA;EACA,YAAA;AAFJ;AAKE;EACE,gBAAA;EACA,iBAAA;AAHJ;AAME;EACE,eAAA;EACA,YAAA;AAJJ;AAOE;EACE,WAAA;EACA,eAAA;AALJ;AAOI;EACE,eAAA;AALN;AASE;EACE,YAAA;EACA,iBAAA;EACA,gBAAA;AAPJ;AAWE;EACE,8CAAA;EACA,mBAAA;EACA,sBAAA;AATJ;AAYE;EACE,mDAAA;AAVJ;AAaE;EACE,oBAAA;AAXJ;AAeE;EACE,aAAA;EACA,gBAAA;AAbJ;AAgBE;EACE,oBAAA;EACA,YAAA;AAdJ;AAiBE;EACE,mBAAA;AAfJ;;AAoBA;EACE,YAAA;EACA,iBAAA;AAjBF","sourcesContent":["$edit-menu-color-dark: #a4a1a1;\n$edit-menu-color-light: #ecebeb;\n\n.fabmenu {\n  position: absolute;\n  top: -0.4rem;\n  left: 2.5rem;\n\n  &__close-menu-icon {\n    cursor: pointer;\n    margin-top: -8px;\n    margin-bottom: -14px;\n    font-size: 2rem;\n    color: white;\n  }\n\n  &__close-menu-label {\n    margin-top: -5px;\n    padding-bottom: 0;\n  }\n\n  &__icon {\n    font-size: 2rem;\n    color: white;\n  }\n\n  &__label {\n    width: 50px;\n    cursor: pointer;\n\n    &--nopointer {\n      cursor: initial;\n    }\n  }\n\n  &__icontitle {\n    color: white;\n    font-size: 0.6rem;\n    line-height: 80%;\n  }\n\n\n  &__list {\n    background-image: var(--linear-gradient-color);\n    border-radius: 15px;\n    padding: 5px 4px 0 4px;\n  }\n\n  &--inactive {\n    background-image: linear-gradient($edit-menu-color-light, $edit-menu-color-dark);\n  }\n\n  &--noclickable {\n    pointer-events: none;\n  }\n\n\n  &--close {\n    display: none;\n    margin-bottom: 0;\n  }\n\n  &__max_dashboards {\n    pointer-events: none;\n    opacity: 70%;\n  }\n\n  &__space {\n    margin-bottom: 10px;\n  }\n\n}\n\n.herosLogo {\n  height: 25px;\n  overflow: initial;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
