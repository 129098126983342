/* eslint-disable @angular-eslint/no-input-rename */
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnChanges,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
  numberAttribute
} from '@angular/core';
import { GridStackWidget } from 'gridstack';
import { Observable } from 'rxjs';
import { JobApplicationService } from 'services/hr-cockpit/job-application.service';
import Swiper from 'swiper';
import { SwiperContainer, register } from 'swiper/element/bundle';

const BATCH_SIZE_LAZY_LOAD_TILES = 7;

register();

interface Settings {
  source: string;
  period: string;
  more: string[];
  minW: number;
  presentation: string;
}

@Component({
  selector: 'app-job-application',
  templateUrl: './job-application.component.html',
  styleUrls: ['./job-application.component.scss'],
})
export class JobApplicationComponent implements OnChanges, AfterViewInit {
  @Input({ required: true}) id;
  @Input({ required: true}) settings;
  @Input({ required: true}) sizeChange: Observable<Event>;
  @Input({ required: true, transform: numberAttribute}) widgetWidth;
  @Input({ required: true, transform: numberAttribute}) widgetHeight = 2;

  @ViewChildren('slide') slides: QueryList<any>;
  @ViewChild('swiper') swiper: ElementRef<SwiperContainer>;

  colsListMode: { [key: number]: [number, number, number, number, number] } = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    1: [4,3,0,4,1],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    2: [4,3,0,4,1],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    3: [4,3,0,4,1],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    4: [4,3,0,4,1],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    5: [4,3,0,4,1],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    6: [4,3,0,4,1],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    7: [4,3,0,4,1],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    8: [3,2,3,3,1],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    9: [3,2,3,3,1],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    10: [2,2,4,3,1],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    11: [2,2,4,3,1],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    12: [2,2,4,3,1],
  };
  jobApplications: JobApplication[];
  offset = 0;

  constructor(
    private jobApplicationService: JobApplicationService,
  ) {
  }

  checkForLazyLoad(swiper?: Swiper) {
    let addition: number | undefined;

    if (typeof swiper?.params?.slidesPerView === 'number') {
      const thresholdForLoadingMore = 3;

      /**
       * If isEnd is true (possibly set by Swiper.js framework), swiper will show the end of the slides.
       * So it wouldn't be the original first slide that would be displayed, but a different one.
       * Setting isEnd to false prevents that
       */
      swiper.isEnd = false;

      /**
       * swiper.realIndex: most left visible slide
       * swiper.params.slidesPerView: the number of slides displayed at the same time
       * swiper.slides.lenght: the number of total slides
       * thresholdForLoadingMore: if there a only X more slides left -> load more
       */
      addition = swiper.realIndex + (swiper.params.slidesPerView - 1) >= swiper.slides.length - thresholdForLoadingMore
                    ? BATCH_SIZE_LAZY_LOAD_TILES
                    : undefined;
    }

    const diff = (this.widgetHeight * 2 - 1) - this.jobApplications.length;
    if (diff > 0 && swiper === undefined) {
      addition = diff;
    }

    if (addition !== undefined) {
      this.getJobApplication({
        limit: addition,
        offset: this.offset,
      }).subscribe((data) => {
        this.jobApplications.push(...data);
        this.offset += addition;
      });
    }
  }

  getJobApplication(
    lazyLoad?: {
      limit: number;
      offset: number;
    }
  ): Observable<JobApplication[]> {
    return this.jobApplicationService.getJobApplication(
      {
        source: this.settings.source,
        presentation: this.settings.presentation,
        period: this.settings.period,
        more: this.settings.more,
      },
      lazyLoad,
    );
  }

  isTypeGridStackWidget(obj: unknown): obj is GridStackWidget {
    return typeof (obj as GridStackWidget)?.id === 'string';
  }

  ngAfterViewInit(): void {
    this.slides.changes.subscribe((data) => {
      (data.first.nativeElement.parentElement.swiper as Swiper).updateSlides();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.settings !== undefined) {

      /**
       * if widget is in tiles presentation mode limit is 4
       * if widget is in list presentation mode its -1 for design reasons
       */
      const limit = changes.settings.currentValue.presentation === 'list' ? (this.widgetHeight * 2 - 1) : 7;
      this.getJobApplication({
        limit,
        offset: 0,
      }).subscribe((data) => {
        this.jobApplications = data;
        this.offset = limit;
        if(this.swiper !== undefined) {
          this.swiper.nativeElement.swiper.slideTo(0, 0);
        }
      });
    }
    this.sizeChange?.subscribe((data) => {
      const items = data[1];
      if (items instanceof Array) {
        this.widgetWidth = items.find((value) => this.isTypeGridStackWidget(value) && value.id === this.id)?.w || this.widgetWidth;
        this.widgetHeight = items.find((value) => this.isTypeGridStackWidget(value) && value.id === this.id)?.h || this.widgetHeight;
        this.checkForLazyLoad();
      }
    });
  }

  onRealIndexChange(event) {
    if(Array.isArray(event.detail)) {
      event.detail.forEach((element: unknown) => {
        if(element instanceof Swiper) {
          this.checkForLazyLoad(element);
        }
      });
    }
  }
}
