import { AuthConfig } from 'angular-oauth2-oidc';

// default
export const ConstantVariable: any = {
    defaultLang: 'de',
    apiPrefix: '/api', // interner prefix in den controllern
    loginState: {
        LOGIN: 1,
        LOGOUT: 2,
    }
};

const testPrefix = 'assets/test/';
export const TestUrlMapping: Map<string, string> = new Map();
// values see: environment.mock.ts

// https://github.com/manfredsteyer/angular-oauth2-oidc
export const authConfig: AuthConfig = {
    // Url of the Identity Provider

    // issuer: 'https://steyer-identity-server.azurewebsites.net/identity',
    skipIssuerCheck: true,
    tokenEndpoint: ConstantVariable.apiPrefix + '/secure/oauth/token',
    responseType: 'code', // muss auf code stehen, damit bei automaticRefresh die grant refresh_token benutzt wird

    // URL of the SPA to redirect the user to after login
    // redirectUri: window.location.origin + '/index.html',

    // The SPA's id. The SPA is registered with this id at the auth-server
    clientId: 'h20',
    dummyClientSecret: 'secret',
    useHttpBasicAuth: false,
    requireHttps: false,

    // set the scope for the permissions the client should request
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    scope: 'write',
}
