import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-news-item',
  templateUrl: './news-item.component.html',
  styleUrls: ['./news-item.component.scss'],
})
export class NewsItemComponent  implements OnInit {
  @Input() title: string;
  @Input() img: string;
  @Input() text: string;
  @Input() date: string;
  @Input() link: string;

  constructor() { }

  ngOnInit() {}

}
