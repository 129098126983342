// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.background-image {
  background-image: url("/assets/images/invoice-overview.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/hr-cockpit/widgets/invoice-overview/invoice-overview.component.scss"],"names":[],"mappings":"AAAA;EACE,4DAAA;EACA,wBAAA;EACA,2BAAA;EACA,4BAAA;EACA,YAAA;EACA,WAAA;AACF","sourcesContent":[".background-image {\n  background-image: url('/assets/images/invoice-overview.png');\n  background-size: contain;\n  background-position: center;\n  background-repeat: no-repeat;\n  height: 100%;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
