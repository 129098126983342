// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-block;
  height: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--color-two);
}

.img-container {
  height: 171px;
}
.img-container img {
  position: static !important;
  object-fit: cover;
}

.text, .date {
  margin-top: 1rem;
}

ion-button {
  padding: 0;
  margin: 1rem 0 0;
  align-self: flex-start;
  --border-color: var(--color-two);
  --color: var(--color-two);
  --border-radius: 100px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/hr-cockpit/widgets/news/news-item/news-item.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,YAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,gBAAA;EACA,uBAAA;AACF;;AAEA;EACE,aAAA;AACF;AACE;EACE,2BAAA;EACA,iBAAA;AACJ;;AAGA;EACE,gBAAA;AAAF;;AAGA;EACE,UAAA;EACA,gBAAA;EACA,sBAAA;EACA,gCAAA;EACA,yBAAA;EACA,sBAAA;AAAF","sourcesContent":[":host {\n  display: inline-block;\n  height: 100%;\n}\n\n.flex-column {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100%;\n}\n\n.title {\n  font-size: 1.5rem;\n  font-weight: 700;\n  color: var(--color-two);\n}\n\n.img-container {\n  height: 171px; // 40% of cards height - 427 * 0.4 = 170.8\n\n  img {\n    position: static !important;\n    object-fit: cover;\n  }\n}\n\n.text, .date {\n  margin-top: 1rem;\n}\n\nion-button {\n  padding: 0;\n  margin: 1rem 0 0;\n  align-self: flex-start;\n  --border-color: var(--color-two);\n  --color: var(--color-two);\n  --border-radius: 100px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
