import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable} from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { of as observableOf} from 'rxjs';

/**
 * https://stackoverflow.com/questions/47811784/passing-authorization-header-for-images-src-to-remote-server-in-ionic-page
 * Beispiel: <img [src]="'https:/url/der/ressource' | secure | async"/>
 * BITTE BEACHTEN: Ressourcen werden gleich nach Seitenaufruf geladen und nicht gestreamt => bei größeren Files ggf. access_token an URL anhängen oder anderen Workaround verwenden
 */
@Pipe({
    name: 'secure'
})
export class SecurePipe implements PipeTransform {

    constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

    transform(url: string): Observable<SafeUrl> {
        if (!url) {
            return observableOf('');
         }

        if (url.startsWith("data:")) {
            return observableOf(url);
        }

        // return from(this.branchConfigProvider.isReady/) .pipe(
        //   mergeMap(() => this.http.get(url, { responseType: 'blob' })),
        //   map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val)))
        // );
    }
}