// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.swiper-container {
  --swiper-theme-color: var(--hr-highlight-color);
  height: 95%;
}

ion-card-title {
  text-align: center;
  font-size: 1.5rem;
  color: var(--text-color);
}`, "",{"version":3,"sources":["webpack://./src/app/components/hr-cockpit/widgets/news/news-container/news-container.component.scss"],"names":[],"mappings":"AAAA;EACE,+CAAA;EACA,WAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,wBAAA;AACF","sourcesContent":[".swiper-container {\n  --swiper-theme-color: var(--hr-highlight-color);\n  height: 95%;\n}\n\nion-card-title {\n  text-align: center;\n  font-size: 1.5rem;\n  color: var(--text-color);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
