import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService, MessageService } from 'app-services';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private messageService: MessageService,
        private authService: AuthenticationService,
        private translate: TranslateService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError(err => {
                if ((err.status === 403 || err.status === 401) && err.url.indexOf('/auth/login') < 0) {
                    // auto logout if 401/403 response returned from api
                    this.authService.logout();
                }

                if (err.error && err.error.messages) {
                    this.messageService.error(err.error.messages.error);

                } else if (err.error && err.error.message && (err.status != 404)) {
                    this.messageService.error(err.error.message);

                } else if (err.status >= 400) {
                    let message = this.translate.instant('global.messages.error' + err.status);
                    if (err.error && (typeof err.error === 'string' || err.error instanceof String)) {
                        message += ': ' + err.error;
                    }
                    this.messageService.error(message);
                } else {
                    this.messageService.error(err.message);
                }
                return throwError(err);
            })
        );
    }
}