// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kpi__circle {
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  height: 70%;
  border-radius: 50%;
  border: solid 2px var(--text-color);
}
.kpi__circle--highlight {
  font-size: 1.6rem;
  line-height: 70%;
  color: var(--on-highlight-color);
  background-color: var(--gradient-target-color);
  background: linear-gradient(45grad, var(--gradient-start-color) 0%, var(--gradient-target-color) 100%);
  border: none;
  box-shadow: 0px 0px 10px 0px var(--gradient-target-color), 0px 0px 0px 5px white, 0px 0px 0px 6px var(--gradient-target-color);
  height: 80px;
}
.kpi__circle-col {
  display: flex;
  justify-content: center;
  align-items: center;
}
.kpi__label {
  font-weight: bold;
  text-align: center;
}
.kpi__label--highlight {
  color: var(--hr-highlight-color);
}
.kpi__menu {
  --offset-x: -36px;
}
.kpi__menu-headline {
  text-transform: uppercase;
  color: var(--text-color-headline);
  font-weight: bold;
  font-size: 1.2rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/hr-cockpit/widgets/kpi/kpi.component.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,WAAA;EACA,kBAAA;EACA,mCAAA;AAAJ;AAEI;EACE,iBAAA;EACA,gBAAA;EACA,gCAAA;EACA,8CAAA;EACA,sGAAA;EACA,YAAA;EACA,8HAAA;EAGA,YAAA;AAFN;AAME;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAJJ;AAOE;EACE,iBAAA;EACA,kBAAA;AALJ;AAOI;EACE,gCAAA;AALN;AASE;EACE,iBAAA;AAPJ;AAUE;EACE,yBAAA;EACA,iCAAA;EACA,iBAAA;EACA,iBAAA;AARJ","sourcesContent":[".kpi {\n  &__circle {\n    font-weight: bold;\n    font-size: 1.2rem;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    aspect-ratio: 1 / 1;\n    height: 70%;\n    border-radius: 50%;\n    border: solid 2px var(--text-color);\n\n    &--highlight {\n      font-size: 1.6rem;\n      line-height: 70%;\n      color: var(--on-highlight-color);\n      background-color: var(--gradient-target-color);\n      background: linear-gradient(45grad, var(--gradient-start-color) 0%, var(--gradient-target-color) 100%);\n      border: none;\n      box-shadow: 0px 0px 10px 0px var(--gradient-target-color),\n      0px 0px 0px 5px white,\n      0px 0px 0px 6px var(--gradient-target-color),;\n      height: 80px;\n    }\n  }\n\n  &__circle-col {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  &__label {\n    font-weight: bold;\n    text-align: center;\n\n    &--highlight {\n      color: var(--hr-highlight-color);\n    }\n  }\n\n  &__menu {\n    --offset-x: -36px;\n  }\n\n  &__menu-headline {\n    text-transform: uppercase;\n    color: var(--text-color-headline);\n    font-weight: bold;\n    font-size: 1.2rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
