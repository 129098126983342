import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { originalOrder } from '../../../../util/comparator';
import { ItemReorderEventDetail } from '@ionic/angular';
import { LinkOption } from 'components/hr-cockpit/widgets/links/links.component';

interface Property {
  name: string;
  label: string;
  form: ColorTheme | DropDown | Radio | Toggle | Link;
}

interface ColorTheme {
  type: 'ColorTheme';
  options: string[];
}

interface DropDown {
  type: 'DropDown';
  options: {
    [key: string]: string;
  };
  onChange?: (event: Event) => void;
}

interface Radio {
  type: 'Radio';
  options: {
    [key: string]: string;
  };
}

interface Toggle {
  type: 'Toggle';
  options: {
    [key: string]: string;
  };
}

interface Link {
  type: 'Link';
  options: {
    [key: string]: LinkOption;
  };
}


@Component({
  selector: 'app-widget-menu',
  templateUrl: './widget-menu.component.html',
  styleUrls: ['./widget-menu.component.scss'],
})
export class WidgetMenuComponent  implements OnInit {
  @ViewChild('menu') menu;
  @Input() settings;
  @Output() settingsChange = new EventEmitter();
  @Input() widgetType: string;

  defaultIconSrc = './../../../../../assets/images/links-widget/portalverwaltung.png';
  linkFormGroup: FormGroup = this.formBuilder.group({
    links: this.formBuilder.array<FormGroup>([]),
  });

  // could be loaded dynamically for reuse of menu component
  propertiesTemplates: {[key: string]: Property[]} = {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    JobApplicationComponent: [
      {
        name: 'source',
        label: 'Auswahl der Quelle',
        form: {
          type: 'Radio',
          options: {
            newest: 'Neueste Bewerbung',
            lastEdited: 'Letzte bearbeitete Bewerbung',
          },
        }
      },
      {
        name: 'period',
        label: 'Zeitraum',
        form: {
          type: 'DropDown',
          options: {
            today: 'Heute',
            lastWeek: 'Letzte Woche',
            last14Days: 'Letzte 14 Tage',
          },
        }
      },
      {
        name: 'more',
        label: 'weitere Anzeigeoptionen (optional)',
        form: {
          type: 'Toggle',
          options: {
            matching: 'Bewertung',
            icons: 'Icons',
            feedback: 'Feedback Weiterleitung',
            checklist: 'Checklist',
          },
        }
      },
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    KpiComponent: [
      {
        name: 'kpi',
        label: 'Verfügbare KPIs',
        form: {
          type: 'DropDown',
          options: {
            timeToHire: 'Time-To-Hire',
            timeToInterview: 'Time-To-Interview',
            costPerHire: 'Cost-Per-Hire',
            vacancies: 'unbesetzte Stellen',
          },
        onChange: (event) => {
            const element = event.target as HTMLSelectElement;
            const property = this.properties.find((value) => value.name === 'kpi').form as DropDown;
            this.settings.title = property.options[element.value];
            this.settingsChange.emit(this.settings);
          },
        }
      },
      {
        name: 'secondarysource',
        label: 'Vergleichsquelle',
        form: {
          type: 'DropDown',
          options: {
            y2023: '2023',
            q42021: 'Q4 2021',
            q12022: 'Q1 2022',
            q22022: 'Q2 2022',
            q32022: 'Q3 2022',
            q42023: 'Q4 2023',
            q12023: 'Q1 2023',
            q22023: 'Q2 2023',
            q32023: 'Q3 2023',
            m012023: 'Januar 2023',
          },
        }
      },
      {
        name: 'primarysource',
        label: 'Datenquelle',
        form: {
          type: 'DropDown',
          options: {
            y2024: '2024',
            q42022: 'Q4 2022',
            q12023: 'Q1 2023',
            q22023: 'Q2 2023',
            q32023: 'Q3 2023',
            q42023: 'Q4 2023',
            q12024: 'Q1 2024',
            q22024: 'Q2 2024',
            q32024: 'Q3 2024',
            m012024: 'Januar 2024',
          },
        }
      },
      {
        name: 'colortheme',
        label: 'Farbschema',
        form: {
          type: 'ColorTheme',
          options: [
            'subtheme-one',
            'subtheme-two',
            'subtheme-three',
          ],
        },
      },
    ],
    // eslint-disable-next-line @typescript-eslint/naming-convention
    LinksComponent: [
      {
        name: 'linkSelection',
        label: 'LinkSelection',
        form: {
          type: 'Toggle',
          options: {
            analytics: 'Analytics',
            portalverwaltung: 'Portalverwaltung',
            performanceboard: 'Performanceboard',
            stellencockpit: 'Stellencockpit',
          },
        }
      },
      {
        name: 'Individual Links',
        label: 'Individual Links',
        form: {
          type: 'Link',
          options: {},
        }
      },
    ]
  };
  properties: Property[];

  protected readonly originalOrder = originalOrder;

  constructor(private formBuilder: FormBuilder) { }

  get linksArray(): FormArray {
    return this.linkFormGroup.get('links') as FormArray;
  }

  addLinkControl() {
    const linkGroup: FormGroup = this.formBuilder.group({
      index: [this.linksArray.length],
      title: [''],
      url: [''],
      src: [this.defaultIconSrc],
      isShown: [true],
    });
    this.linksArray.push(linkGroup);
  }

  removeLinkControl(index: number) {
    this.linksArray.removeAt(index);
  }

  colorThemeSelect(theme: string): void {
    this.settings.colortheme = theme;
    this.settingsChange.emit(this.settings);
  }

  dropDownOnChange(event: Event) {
    const element = event.target as HTMLSelectElement;
    this.settings[element.name] = element.value;
    const property = this.properties.find((value) => value.name === element.name);
    if('onChange' in property.form && typeof property.form.onChange !== 'undefined') {
      property.form.onChange(event);
    }
    this.settingsChange.emit(this.settings);
  }

  isTypeColorTheme(obj: unknown): obj is ColorTheme {
    return (obj as ColorTheme).type === 'ColorTheme';
  }

  isTypeDropDown(obj: unknown): obj is DropDown {
    return (obj as DropDown).type === 'DropDown';
  }

  isTypeRadio(obj: unknown): obj is Radio {
    return (obj as Radio).type === 'Radio';
  }

  isTypeToggle(obj: unknown): obj is Toggle {
    return (obj as Toggle).type === 'Toggle';
  }

  isTypeLink(obj: unknown): obj is Link {
    return (obj as Link).type === 'Link';
  }

  toggleMenu(isOn?: boolean) {
    const card = this.menu.el.closest('div.grid-stack-item-content') as Element;
    const widget = card.querySelector('ION-CARD.card__ion');
    const menu = card.querySelector('.card__widget-menu') as Element;
    if(menu.classList.contains('card__widget-menu--visible') || isOn === false) {
      card.setAttribute('style', 'overflow: unset');
      widget.setAttribute('style', 'z-index: unset');
      menu.classList.remove('card__widget-menu--visible');
    } else if(!menu.classList.contains('card__widget-menu--visible') || isOn) {
      card.setAttribute('style', 'overflow: visible');
      widget.setAttribute('style', 'z-index: 2');
      menu.classList.add('card__widget-menu--visible');
    }
  }

  ngOnInit() {
    this.properties = this.propertiesTemplates[this.widgetType];
  }

  onIonChangeToggle(propertyName: string, elementKey: string) {
    if (this.settings[propertyName] === undefined) {
      this.settings[propertyName] = [];
    }
    if(this.settings[propertyName].includes(elementKey)) {
      this.settings[propertyName].splice(this.settings[propertyName].indexOf(elementKey), 1);
    } else {
      this.settings[propertyName].push(elementKey);
    }

    // triggering change detection
    this.settings[propertyName] = [...this.settings[propertyName]];
    this.settingsChange.emit(this.settings);
  }

  onIonChangeRadio(event: CustomEvent, propertyName: string) {
    this.settings[propertyName] = event.detail.value;
    this.settingsChange.emit(this.settings);
  }

  updateLinks(indexes: number[], deleteLink: boolean) {
    const links: LinkOption[] = [];
    indexes.forEach((i) => {
      const updateLinkOption: LinkOption = {
        index: this.linksArray.at(i).value.index,
        title: this.linksArray.at(i).value.title,
        url: this.linksArray.at(i).value.url,
        src: this.linksArray.at(i).value.src,
        isShown: !deleteLink,
      };
      if (deleteLink) {
        this.removeLinkControl(i);
      }
      links.push(updateLinkOption);
    });
    this.linksArray.controls.map((control, index) => {
      control.get('index')?.setValue(index);
    });
    this.settingsChange.emit({individualLinks: links});
  }

  onFileSelected(event: Event, control: AbstractControl) {
    const file: File = (event.currentTarget as HTMLInputElement).files[0];
    control.get('src')?.setValue(window.URL.createObjectURL(file));
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
    ev.detail.complete(this.linksArray.controls);
    this.linksArray.controls.map((control, index) => {
      control.get('index')?.setValue(index);
    });
    this.updateLinks([...Array(this.linksArray.length).keys()], false);
  }
}
