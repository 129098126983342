import { Component, HostListener, Input, OnInit, TemplateRef, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
})
export class ContentComponent implements OnInit {

  // keep in sync width sidebar.component.scss and https://ionicframework.com/docs/layout/grid#default-breakpoints
  private static SMALL_BREAKPOINT = 576;

  @Input() sidebarTemplate: TemplateRef<any>;

  public smallScreen: boolean;


  constructor() { }

  ngOnInit() {
    this.onWindowResize();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.smallScreen = window.innerWidth < ContentComponent.SMALL_BREAKPOINT;
  }
}
