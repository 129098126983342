// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
}

.container {
  width: calc(100% - var(--he-sidebar-width));
  display: inline-block;
}

.container:only-child {
  width: 100%;
}

.content-wrapper {
  min-height: 100%;
  background-color: var(--he-content-background);
}`, "",{"version":3,"sources":["webpack://./src/app/components/content/content.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,2CAAA;EACA,qBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,gBAAA;EACA,8CAAA;AACJ","sourcesContent":[":host {\n    height: 100%;\n}\n\n.container {\n    width: calc(100% - var(--he-sidebar-width));\n    display: inline-block;\n}\n\n.container:only-child {\n    width: 100%;\n}\n\n.content-wrapper {\n    min-height: 100%;\n    background-color: var(--he-content-background);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
